#work {
  .app__wrapper {
    .copyright {
      display: none;
    }
  }
}

.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__work-portfolio.animate-enter {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.app__work-portfolio.animate-exit {
  opacity: 0;
  transform: translate(0, 50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  .app__work-item {
    width: 270px;
    flex-direction: column;
    margin: 0.5rem;

    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
      transition: transform 0.25s ease;

      &:hover {
        transform: scale(0.8);
      }
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h3 {
    margin-top: 1rem;
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  p {
    text-align: justify;
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;
  }
}

.app__load-more {
  position: relative;
  width: 130px;
  height: 50px;

  .app__load-more-button {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: var(--secondary-color);
    border-radius: 10px;
    color: white;
    z-index: 1;
    font-weight: 400;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    border: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.15),
        transparent
      );
      transform: skewX(45deg) translateX(0);
      transition: 0.5s;
    }
  }

  &:hover {
    button {
      letter-spacing: 2px;

      &::before {
        transform: skewX(45deg) translateX(20%);
      }
    }

    &::before {
      bottom: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
    }

    &::after {
      top: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
    }
  }
}
