.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;


  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-container {
  width: 80%;

  display: grid;
  grid-template-columns: 1FR 1FR;

  @media screen and (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1FR;

  }
}


.app__footer-img {
  width: 50%;
}

.app__thanks {
  flex-direction: column;

  .head-text {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--secondary-color);
  }
}

.app__footer-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 2rem;

  .app__footer-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;

      @media screen and (max-width: 280px) {
        margin: 0 0.3rem;
      }

    }

    p {
      font-weight: 500;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}


.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;

      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }

    textarea {
      height: 170px;
    }




  }


  .app_row {
    gap: 20px;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }
  }

  .form__btn-container {

    position: relative;
    width: 130px;
    height: 50px;
    margin-top: 20px;

    .form__btn {
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      background: var(--secondary-color);
      border-radius: 10px;
      color: white;
      z-index: 1;
      font-weight: 400;
      font-family: var(--font-base);
      text-decoration: none;
      overflow: hidden;
      transition: 0.5s;
      border: none;


      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
        transform: skewX(45deg) translateX(0);
        transition: 0.5s;
      }
    }

    &:hover {

      button {
        letter-spacing: 2px;

        &::before {
          transform: skewX(45deg) translateX(20%);
        }
      }

      &::before {
        bottom: 0;
        height: 50%;
        width: 80%;
        border-radius: 30px;
      }

      &::after {
        top: 0;
        height: 50%;
        width: 80%;
        border-radius: 30px;
      }
    }

  }


  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}