#home {
  padding-top: 50px;

  .app__wrapper {
    margin: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.2fr;

  @media screen and (max-width: 912px) {
    grid-template-columns: 1fr;
  }
}

.app__header-info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-left 1s ease 0s 1 normal forwards;

  .app__header-wrapper {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    line-height: 2;

    @media screen and (max-width: 912px) {
      padding-left: 0;
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
    }

    .app__header-intro {
      font-size: 30px;
      font-weight: 300;
    }

    .app__header-name {
      font-size: 60px;
      line-height: 1.2;

      @media screen and (max-width: 280px) {
        font-size: 40px;
      }
    }

    .app__header-title {
      height: 50px;
      overflow: hidden;

      .app__header-title-wrapper {
        height: 100%;

        .app__header-title-item {
          height: 50px;
          font-size: 30px;
          font-weight: bold;
          color: var(--secondary-color);
          display: flex;
          align-items: center;

          @media screen and (max-width: 912px) {
            justify-content: center;
          }

          @media screen and (max-width: 480px) {
            font-size: 20px;
          }
        }
      }
    }

    .app__header-desc {
      margin-top: 20px;
      font-size: 15px;

      @media screen and (max-width: 912px) {
        font-size: 20px;
      }

      @media screen and (max-width: 540px) {
        display: none;
      }
    }

    .app_header-btn-container {
      position: relative;
      width: 130px;
      height: 50px;
      margin-top: 20px;

      .app__header-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        background: var(--secondary-color);
        border-radius: 10px;
        color: white;
        z-index: 1;
        font-weight: 400;
        text-decoration: none;
        overflow: hidden;
        transition: 0.5s;
        gap: 5px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.15),
            transparent
          );
          transform: skewX(45deg) translateX(0);
          transition: 0.5s;
        }
      }

      &:hover {
        a {
          letter-spacing: 2px;

          &::before {
            transform: skewX(45deg) translateX(20%);
          }
        }

        &::before {
          bottom: 0;
          height: 50%;
          width: 80%;
          border-radius: 30px;
        }

        &::after {
          top: 0;
          height: 50%;
          width: 80%;
          border-radius: 30px;
        }
      }
    }
  }

  @keyframes slide-left {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.app__header-img {
  flex: 1;
  position: relative;
  animation: slide-left 1s ease 0s 1 normal forwards;

  img {
    position: relative;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
