.checkbox {
    opacity: 0;
    position: absolute;
    display: none
}


.toggle-ball {
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    cursor: pointer;
    transition: transform 0.2s linear;
}

.label {
    width: 50px;
    height: 26px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
}

.fa-moon {
    color: pink;
}

.fa-sun {
    color: yellow;
}