#about {
  .app__wrapper {
    .copyright {
      display: none;
    }
  }
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.content-text {
  width: 80%;
  margin-top: 20px;
  line-height: 2;
  text-align: justify;
  text-indent: 2rem;
}

.app__profiles {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 912px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width: 1024px) and (height: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  // @media screen and (max-width: 540px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
}

.app__profile-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  animation: fade-forward 2s ease 0s 1 normal forwards;
  h2 {
    text-align: center;
  }
  @keyframes fade-forward {
    0% {
      opacity: 0;
      transform: scale(0.6);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: contain;

    @media screen and (max-width: 540px) {
      height: 75px;
    }
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }

  @media screen and (max-width: 540px) {
    margin: 1rem;
  }
}
