:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --primaryDark-color: #5f2f7c;
  --secondaryDark-color: #b882ed;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --dark-color: #141547;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
