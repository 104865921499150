.app {
  background-color: var(--white-color);
  font-family: var(--font-base);
}

.app__primarybg {
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin: 2rem;
}

.copyright {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.app__social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 72px;
  padding: 1rem;

  a {
    display: flex;
    align-items: center;
  }
  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 72px;
  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

// DARK MODE

.app.dark {
  background-color: var(--dark-color);
  color: #fff;

  ::placeholder {
    color: var(--lightGray-color);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: var(--lightGray-color);
  }

  ::-ms-input-placeholder {
    color: var(--lightGray-color);
  }

  .p-text {
    color: var(--lightGray-color);
  }

  h1,
  h2,
  h3,
  h4,
  p {
    color: #fff;
  }

  span {
    color: var(--secondaryDark-color);
  }

  .app__primarybg {
    background: linear-gradient(
      179.4deg,
      rgb(12, 20, 69) -16.9%,
      rgb(71, 30, 84) 119.9%
    );
  }

  .app__work-content .app__work-tag {
    background: var(--primaryDark-color);
  }

  .app__social div {
    border: none;

    svg {
      color: var(--lightGray-color);
    }
  }

  .app__header-name {
    color: #eebe53;
    text-shadow: 0 0 1px #000;
  }

  .app__header-desc,
  .content-text {
    color: var(--lightGray-color);
  }

  .app__work-portfolio {
    .app__work-item {
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .app__work-portfolio .app__work-item,
  .app__testimonial-item,
  .app__skills-item div,
  .app__footer-form div input,
  .app__footer-form div textarea,
  .app__social div,
  .app__testimonial-btns div,
  .app__work-filter .app__work-filter-item {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 10px 10px 20px -10px rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .app__testimonial-brands {
    div {
      img {
        filter: grayscale(1) brightness(150%);
      }

      &:hover {
        img {
          filter: grayscale(0);
        }
      }
    }
  }

  .app__testimonial-btns {
    div {
      svg {
        color: var(--lightGray-color);
      }

      &:hover {
        background-color: #8531ac;

        svg {
          color: var(--white-color);
        }
      }
    }
  }

  .app__footer-cards .app__footer-card {
    &:hover {
      box-shadow: none;
    }
  }

  .app__footer-form {
    div {
      input,
      textarea {
        color: var(--secondaryDark-color);

        &:hover {
          box-shadow: 0 0 5px var(--primary-color);
        }
      }
    }

    .form__btn-container {
      .form__btn {
        background: #8531ac;
      }
    }
  }

  .app__testimonial-content h5,
  .app__skills-exp-works .app__skills-exp-work p {
    color: var(--lightGray-color);
  }

  .app__skills-item {
    div {
      &:hover {
        box-shadow: 0 0 5px #fef4f5;
      }
    }
  }

  .app__work-filter .item-active {
    background-color: #8531ac;
  }

  .app__header-info
    .app__header-wrapper
    .app__header-title
    .app__header-title-wrapper
    .app__header-title-item,
  .app__skills-exp-year p {
    color: var(--secondaryDark-color);
  }

  .app__header-info
    .app__header-wrapper
    .app_header-btn-container
    .app__header-button {
    background: #8531ac;
  }

  .app__navigation .app__navigation-dot,
  .app__social div {
    &:hover {
      background-color: var(--secondaryDark-color);
    }
  }

  .app__navbar-links {
    li {
      a {
        color: var(--lightGray-color);

        &:hover {
          color: var(--secondaryDark-color);
        }
      }

      &:hover {
        div {
          background: var(--secondaryDark-color);
        }
      }
    }
  }

  .app__navbar-menu {
    background-color: var(--secondaryDark-color);

    div {
      background: linear-gradient(
        179.4deg,
        rgb(12, 20, 69) -16.9%,
        rgb(71, 30, 84) 119.9%
      );

      svg {
        color: var(--secondaryDark-color);
      }

      li {
        a {
          color: var(--lightGray-color);
        }
      }
    }
  }

  .app__load-more {
    .app__load-more-button {
      background: #8531ac;
    }
  }
}
